<template>
  <b-container>
    <!-- <validation-observer ref="updateCampaignForm"> -->
    <validation-observer ref="updateCampaignForm" #default="{ invalid }">
      <b-form id="updateCampaignForm" @submit.prevent="updateCampaign(invalid)">
        <b-card class="d-flex">
          <b-row class="border rounded mx-1 mb-2 p-2 justify-content-center">
            <b-col cols="1">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="flat-warning"
                class="btn-icon m-0"
                to="/campaigns"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="11">
              <h1>Campaign Editing</h1>
            </b-col>
          </b-row>
          <b-row class="border rounded mb-2 p-2 justify-content-center mx-1">
            <b-col cols="12" class="mr-1">
              <b-container>
                <b-row>
                  <b-col cols="4" class="mb-2">
                    <h5 class="mb-0">Campaign Details :</h5>
                    <small class="text-muted">
                      Enter Your Campaign Details.
                    </small>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Campaign name: *" label-for="h-title">
                      <validation-provider
                        #default="{ errors }"
                        name="Campaign name"
                        rules="required|regex:^[a-zA-Z].*$"
                      >
                        <b-form-input
                          id="h-title"
                          v-model="title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Campaign name"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" offset="4">
                    <b-form-group label="Domains: *" label-for="did">
                      <validation-provider
                        #default="{ errors }"
                        name="Domains"
                        rules="required"
                      >
                        <v-select
                          id="did"
                          v-model="did"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          :state="errors.length > 0 ? false : null"
                          label="title"
                          :options="domains"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="border rounded mb-2 justify-content-center mx-1">
            <b-col cols="12" class="ml-2 p-2">
              <b-container>
                <b-row>
                  <b-col cols="4" class="mb-2">
                    <h5 class="mb-0">Campaign Type :</h5>
                    <small class="text-muted">
                      Choose The Campaign Type.
                    </small>
                  </b-col>
                  <b-col cols="2" v-for="item in templateModels" :key="item.id">
                    <label>{{ item.title }} :</label>
                    <b-img
                      style="cursor: pointer"
                      :class="{ templateModelIsSelected: item.selectedModal }"
                      thumbnail
                      fluid
                      :src="path + item.image_preview"
                      alt=""
                    />
                    <!-- @click="campaignTypeClicked(item.id, item.title)" -->
                    <input type="hidden" v-model="campaignTypeSelected" />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row
            class="border rounded mx-1 mb-2 py-3 px-1 justify-content-center"
            v-if="showAppAd"
          >
            <!-- <b-row v-if="!this.showAppAd == 1"> -->
            <!-- <b-row v-if="this.showAppAd"> -->
            <b-col cols="6" class="mr-2 border p-2 rounded">
              <!-- <div v-if="this.campaignTypeOnTheSelection == 'app'"></div> -->
              <b-container>
                <b-row>
                  <b-col cols="12">
                    <b-container>
                      <b-row>
                        <b-col cols="12" class="mb-2">
                          <h5 class="mb-0">Campaign for an Application Ad :</h5>
                          <small class="text-muted">
                            Enter Your Campaign Template Details.
                          </small>
                        </b-col>

                        <imgmng class="offset-md-7" />
                        <!-- @onInsertImage="insertInsideTemplate" -->
                        <b-col cols="12">
                          <b-form-group label="Title: *" label-for="h-ad-title">
                            <validation-provider
                              #default="{ errors }"
                              name="Title"
                              rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$"
                            >
                              <b-form-input
                                id="h-campaign-title"
                                v-model="campaignAppAd.title"
                                placeholder="Campaign title"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Main Image: *"
                            label-for="h-main-image"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Main Image link"
                              rules="required|url"
                            >
                              <b-form-input
                                id="h-main-image"
                                v-model="campaignAppAd.headerImage"
                                placeholder="Enter Main Image link"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Icon Image: *"
                            label-for="h-icon-image"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Icon Image link"
                              rules="required|url"
                            >
                              <b-form-input
                                id="h-icon-image"
                                v-model="campaignAppAd.iconImage"
                                placeholder="Enter Icon Image link"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Short Description: *"
                            label-for="h-short-description"
                          >
                            <!-- <div class="btn btn-warning" @click="printDescr">
                            Show Descr
                          </div> -->
                            <validation-provider
                              #default="{ errors }"
                              name="Short Description"
                            >
                              <!-- rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$" -->
                              <b-form-textarea
                                id="textarea-shortDescription"
                                placeholder="Enter the short description..."
                                rows="8"
                                v-model="campaignAppAd.shortDescription"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="5">
              <b-container
                class="border rounded textStart"
                style="height: 590px"
              >
                <b-row
                  style="direction: rtl !important"
                  class="text-wrap text-break"
                >
                  <b-col cols="12" class="bg-dark p-0 overflow-hidden">
                    <div
                      id="preview-headerImage"
                      class="text-center img"
                      style="height: 10rem"
                    >
                      <!-- class="img-fluid img-responsive" -->
                      <img
                        v-if="campaignAppAd.headerImage"
                        style="width: 100%; height: 100%"
                        :src="campaignAppAd.headerImage"
                        alt=""
                      />
                      <feather-icon
                        v-if="!campaignAppAd.headerImage"
                        icon="ImageIcon"
                        class="align-middle h-100"
                        size="50"
                      />
                    </div>
                  </b-col>
                  <b-col cols="3" class="mt-2">
                    <div
                      id="preview-iconImage"
                      class="text-center bg-dark rounded-circle"
                      style="height: 64px"
                    >
                      <img
                        v-if="campaignAppAd.iconImage"
                        class="rounded-circle"
                        style="width: 100%; height: 100%"
                        :src="campaignAppAd.iconImage"
                        alt=""
                      />
                      <feather-icon
                        v-if="!campaignAppAd.iconImage"
                        icon="ImageIcon"
                        class="align-middle h-100"
                        size="30"
                      />
                    </div>
                  </b-col>
                  <b-col cols="9" class="mt-2">
                    <h5
                      v-if="!campaignAppAd.title"
                      id="preview-title"
                      class="card-title font-weight-bold"
                    >
                      لوريم إيبسوم
                    </h5>
                    <h5
                      v-if="campaignAppAd.title"
                      id="preview-title"
                      class="card-title font-weight-bold"
                    >
                      {{ campaignAppAd.title }}
                    </h5>
                  </b-col>

                  <b-col cols="12" class="mt-1" style="direction: rtl">
                    <p
                      class="lh-lg"
                      id="preview-shortDescription"
                      v-if="!campaignAppAd.shortDescription"
                    >
                      لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن
                      الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع
                      ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي
                      منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من
                      الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل
                      أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على
                      هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
                      والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن
                      مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي
                      مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور
                      برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus
                      PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
                    </p>
                    <p
                      class="lh-lg"
                      id="preview-shortDescription"
                      v-if="campaignAppAd.shortDescription"
                    >
                      {{ campaignAppAd.shortDescription }}
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="border rounded mb-2 mx-1 p-2 justify-content-center">
            <b-col cols="12">
              <b-container>
                <b-row>
                  <b-col cols="4" class="mb-2">
                    <h5 class="mb-0">Campaign Settings :</h5>
                    <small class="text-muted">
                      Enter The Campaign Setting Details.
                    </small>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Button Text: *" label-for="btn-text">
                      <validation-provider
                        #default="{ errors }"
                        name="Button Text"
                        rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$"
                      >
                        <b-form-input
                          v-model="button_text"
                          :state="errors.length > 0 ? false : null"
                          id="btn-text"
                          placeholder="Button Text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" offset="4">
                    <b-form-group label="Button Link: *" label-for="btnLink">
                      <!-- <validation-provider #default="{ errors }" name="Button Link" rules="required|url"> -->
                      <validation-provider
                        #default="{ errors }"
                        name="Button Link"
                        rules="required"
                      >
                        <b-form-input
                          id="btnLink"
                          v-model="button_link"
                          :state="errors.length > 0 ? false : null"
                          type="url"
                          placeholder="https://www.example.com"
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="border rounded p-2 mx-1">
            <b-col cols="3" offset="4" style="padding-left: 1.4rem !important">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                class="mr-1"
                type="submit"
              >
                <!-- :disabled="invalid" -->
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-secondary"
                @click="comfirmCancelEditing"
              >
                <!-- :disabled="invalid" -->
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-cancel-editing"
      ok-variant="warning"
      ok-title="Confirm"
      modal-class="modal-warning"
      centered
      title="Cancel Editing!"
      @ok="cancelEditing"
    >
      Are you sure you want to cancel campaign editing ?
    </b-modal>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";
import Editor from "@tinymce/tinymce-vue";

import {
  BRow,
  BCol,
  BCard,
  BImg,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";
import {
  composeHeaderImage,
  composeShortDescription,
  composeIconImageAndTitle,
  composeContainer,
} from "@/helper/helpers-funcs.js";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BAvatar,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    imgmng: imagesManagement,
    BFormFile,
    Editor,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: null,
      // url: null,
      image: null,
      imageToUpdate: null,
      did: [],
      templateModels: [],
      templateContent: null,
      campaignTypeOnTheSelection: null,
      campaignTypeSelected: null,
      button_id: null,
      button_text: null,
      button_link: null,
      addon_id: null,
      campaign_type: null,
      domains: [],
      data: {},
      showAppAd: false,
      campaignAppAd: {
        id: null,
        title: null,
        shortDescription: null,
        headerImage: null,
        iconImage: null,
      },
      // validation rules
      required,
      regex,
      url,
      path: this.$ImagesURL + "template/",
    };
  },
  mounted() {
    this.showAllTemplateModels();
    this.showAppAd = false;
  },
  computed: {
    campaignId() {
      return this.$store.state.appsManager.campaignID;
    },
    allCampaigns() {
      return this.$store.state.appsManager.campaigns;
    },
    allDomains() {
      return this.$store.state.appsManager.domains;
    },
  },
  created() {
    this.appendCampaign();
  },
  methods: {
    comfirmCancelEditing() {
      this.$root.$emit("bv::show::modal", "modal-cancel-editing");
    },

    cancelEditing() {
      this.$router.push({ name: "campaigns" });
    },
    previewTemplateOfCampaign() {
      localStorage.setItem(
        "templateContent",
        JSON.stringify(this.templateContent)
      );
      let routeData = router.resolve({
        name: "editor-preview-content",
      });
      window.open(routeData.href, "_blank");
    },
    async showAllTemplateModels() {
      this.$store
        .dispatch("appsManager/getAllTemplateModels")
        .then(({ data }) => {
          data["template-models"].forEach((element) => {
            element.selectedModal = false;
            if (element.title == this.campaign_type) {
              this.campaignTypeOnTheSelection = this.campaign_type;
              element.selectedModal = true;

              if (this.campaignTypeOnTheSelection == "app") {
                this.showAppAd = true;
              }
            }
          });
          this.templateModels = data["template-models"];
        })
        .catch(console.error);
    },
    appendCampaign(id) {
      if (this.allCampaigns.length > 0) {
        let items = this.allCampaigns;

        let _campaign = items.find((o) => o.id == this.campaignId);

        if (_campaign.domains.length > 0) {
          _campaign.domains.forEach((element) => {
            this.did.push({
              title: element.domain_name,
              value: element.id,
            });
          });
        }

        this.title = _campaign.title;
        this.templateContent = _campaign.template;
        this.button_text = _campaign.addons[0].text;
        this.button_link = _campaign.addons[0].button_link;
        this.addon_id = _campaign.addons[0].id;
        this.campaign_type = _campaign.campaign_types.name;
        this.campaignAppAd.id = _campaign.app_detail.id;
        this.campaignAppAd.title = _campaign.app_detail.title_beside_icon;
        this.campaignAppAd.headerImage = _campaign.app_detail.header_image;
        this.campaignAppAd.iconImage = _campaign.app_detail.icon_image;
        this.campaignAppAd.shortDescription =
          _campaign.app_detail.short_description;
        this.showAllDomains();
      } else {
        this.$store
          .dispatch("appsManager/getAllCampaigns")
          .then(({ data }) => {
            let items = data.campaigns;
            let _campaign = items.find((o) => o.id == this.campaignId);

            if (_campaign.domains.length > 0) {
              _campaign.domains.forEach((el) => {
                this.did.push({
                  title: el.domain_name,
                  value: el.id,
                });
              });
            }

            this.title = _campaign.title;
            this.templateContent = _campaign.template;
            this.button_text = _campaign.addons[0].text;
            this.button_link = _campaign.addons[0].button_link;
            this.addon_id = _campaign.addons[0].id;
            this.campaign_type = _campaign.campaign_types.name;
            this.campaignAppAd.id = _campaign.app_detail.id;
            this.campaignAppAd.title = _campaign.app_detail.title_beside_icon;
            this.campaignAppAd.headerImage = _campaign.app_detail.header_image;
            this.campaignAppAd.iconImage = _campaign.app_detail.icon_image;
            this.campaignAppAd.shortDescription =
              _campaign.app_detail.short_description;
            this.showAllDomains();
          })
          .catch(console.error);
      }
    },
    async showAllDomains() {
      if (this.allDomains.length > 0) {
        this.allDomains.forEach((el) => {
          this.domains.push({ title: el.domain_name, value: el.id });
        });
      } else {
        this.$store
          .dispatch("appsManager/getAllDomains")
          .then(({ data }) => {
            data[0].forEach((el) => {
              this.domains.push({ title: el.domain_name, value: el.id });
            });
          })
          .catch(console.error);
      }
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.imageToUpdate = e.target.files[0];
    },
    templateComposer() {
      if (
        (this.campaignAppAd.title &&
          this.campaignAppAd.shortDescription &&
          this.campaignAppAd.headerImage &&
          this.campaignAppAd.iconImage) != null
      ) {
        const headerImage = composeHeaderImage(
          this.campaignAppAd.headerImage,
          this.campaignAppAd.title
        );
        const iconImageAndTitle = composeIconImageAndTitle(
          this.campaignAppAd.iconImage,
          this.campaignAppAd.title
        );
        const shortDescription = composeShortDescription(
          this.campaignAppAd.shortDescription
        );
        const container = composeContainer(
          headerImage,
          iconImageAndTitle,
          shortDescription
        );
        return container;
      }
    },
    async updateCampaign(i) {
      this.$refs.updateCampaignForm.validate().then((success) => {});
      if (!i) {
        //check if the fields not all of them are empty
        if (
          !this.title &&
          !this.templateContent &&
          // !this.imageToUpdate &&
          // !this.url &&
          this.did.length < 1
        ) {
          this.showToast(
            "warning",
            "AlertOctagonIcon",
            "Alert",
            `You need to fill a field so as updating it.`
          );
          return;
        }
        //fill the IDs of the selected domains
        let values = [];
        this.did.forEach((element) => {
          values.push(element.value);
        });
        let allCampaignColumns = {
          id: this.campaignId,
          title: this.title,
          did: values,
          campaign_type: this.campaignTypeOnTheSelection,
          addon_id: this.addon_id,
          button_text: this.button_text,
          button_link: this.button_link,
          campaign_app_ad_id: this.campaignAppAd.id,
        };
        //  {
        //           id: this.campaignId,
        //           title: this.title,
        //           template: this.templateContent,
        //           did: values,
        //           addon_id: this.addon_id,
        //           button_text: this.button_text,
        //           button_link: this.button_link,
        //           campaign_app_ad_id: this.campaignAppAd.id,
        //           title_beside_icon: this.campaignAppAd.title,
        //           header_image: this.campaignAppAd.headerImage,
        //           icon_image: this.campaignAppAd.iconImage,
        //           short_description: this.campaignAppAd.shortDescription,
        //         })
        if (this.campaignTypeOnTheSelection == "app") {
          allCampaignColumns.title_beside_icon = this.campaignAppAd.title;
          allCampaignColumns.header_image = this.campaignAppAd.headerImage;
          allCampaignColumns.icon_image = this.campaignAppAd.iconImage;
          allCampaignColumns.short_description =
            this.campaignAppAd.shortDescription;
          allCampaignColumns.template = this.templateComposer();
        } else if (this.campaignTypeOnTheSelection == "store") {
          allCampaignColumns.template = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
</body>
</html>`;
        }
        await axios
          .post("/update-campaign", allCampaignColumns)
          .then(({ data }) => {
            if (data.status == "success") {
              this.showToast("success", "SaveIcon", "Done", data.message);
              router.push({ name: "campaigns" });
            } else if (data.status == "failed") {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Something went wrong!"
              );
            } else if (data.status == "warning") {
              this.showToast(
                "warning",
                "AlertOctagonIcon",
                "Alert",
                data.message
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.templateModelIsSelected {
  border: 3.5px solid #1094f3;
  padding: 0;
}
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>